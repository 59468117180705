.maq_accordion {
    width: 70%;
    margin: auto;
    padding: 30px 0;
}


.maq_accordion .MuiPaper-root {
    background-color: rgb(217, 222, 225);
    padding-left: 0;
    padding-right: 0;
    box-shadow: unset;
    border-radius: 4px;
    margin: 20px 0;
}

.maq_accordion .maq_accordion-bigdiv {
    border-radius: 8px;
    margin: 10px 0;
    padding: 5px;

}

.maq_accordion .maq_bigdiv-ques {
    font-weight: 500 !important;
    font-size: 18px !important;
}

.maq_accordion .maq_bigdiv-answr {
    background-color: white;
    font-size: 16px !important;
    padding: 10px 20px;
    letter-spacing: 1px !important;

}

.maq_accordion .css-15v22id-MuiAccordionDetails-root {
    padding: 0;
}

@media screen and (max-width:767px) {
    .maq_accordion {
        width: 90%;
    }
    .maq_accordion .maq_bigdiv-ques {
        font-size: 17px !important;
    }

    .maq_accordion .maq_bigdiv-answr {
        font-size: 14px !important;

    }
}