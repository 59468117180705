
.ourdiv_clients {
    text-align: center;
    width: 100%;
    margin: auto;
    padding: 50px;
    background-color: #eee9e9;
}

.ourdiv_clients h1 {
    font-size: 40px;
    font-weight: bold;
    font-family: "Archivo Black", sans-serif;
    margin-bottom: 40px;
}

.ourdiv_clients p {
    font-size: 16px;
}

.ourclients_partner{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.Projects_Cleintstopimg {
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    width: 300px;
    padding: 20px;
    background-color: white;
}

.Projects_Cleintstopimg img {
    height: 100px;
    width: 200px;
    object-fit: contain;
    margin: auto;
    align-items: center;
    border-radius: 4px;
}

.Projects_Cleintstopimg p {
    font-weight: bold;
    margin-top: 20px;
}


