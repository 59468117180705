

.CommercialResidential_topdiv {
    margin-bottom: 13%;
    z-index: -44 !important;
    position: relative;
    z-index: -1;
}

.CommercialResidential_topdiv img {
    width: 100%;
    height: 90vh;
    object-fit: cover;
    margin-bottom: 0px;
    /* filter: brightness(40%); */
    z-index: -44 !important;

}

.CommercialResidential_topdiv .hosp_Residentialhead {
    margin-top: -27%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);
    z-index: -44 !important;


}

.CommercialResidential_topdiv .hosp_Residentialhead h1 {
    font-weight: bold;
    font-size: 70px;
    letter-spacing: 2px;
    color: white;
    z-index: -99994 !important;
    text-transform: uppercase;
    text-shadow: 0 0 20px black;
    font-family: "Archivo Black", sans-serif;
    
}

.SpaceCount_div {
    background-color: #0a0a0a;
    padding: 60px 5px;
    text-align: center;
}

.SpaceCount_div h1 {
    color: white;
    font-family: "Archivo Black", sans-serif;

}


.SpaceCount_slidediv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.SpaceCount_slidediv a {
    text-decoration: none;
}

.SpaceCount_div_slide {
    margin: 10px;
    width: 360px;
    height: auto;
    text-align: center;
    border-radius: 14px 14px 10px 10px;
    background-color: white;
    padding: 20px 0;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.SpaceCount_div_slide img {
    height: 80px;
    /* border: 5px solid white; */
    width: 80px;
    margin: 10px auto 10px auto;
    object-fit: contain
        /* border-radius: 44px 44px 0 0; */
}

.SpaceCount_div_slide h4 {
    margin: 10px 20px 0 20px;
    font-weight: bold;
    font-family: "Archivo Black", sans-serif;
    font-size: 22px;
}

.SpaceCount_div_slide p {
    margin: 20px;
    font-size: 17px;
    letter-spacing: 1px;

}


@media screen and (max-width:767px) {
    .CommercialResidential_topdiv {
        margin-bottom: 23%;
    }
    
    .CommercialResidential_topdiv img {
        height: 250px;
    }

    .CommercialResidential_topdiv .hosp_Residentialhead {
        margin-top: -42%;
        padding: 0 20px;
        text-align: center;

    }

    .CommercialResidential_topdiv .hosp_Residentialhead h1 {
        font-size: 30px;
    }

}