.Aboutus_topdiv {
    margin-bottom: 13%;
    z-index: -44 !important;
    position: relative;
    z-index: -1;
}

.Aboutus_topdiv img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    margin-bottom: 0px;
    /* filter: brightness(40%); */
    z-index: -44 !important;

}

.Aboutus_topdiv .hosp_aboutushead {
    margin-top: -20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);
    z-index: -44 !important;


}

.Aboutus_topdiv .hosp_aboutushead h1 {
    font-weight: bold;
    font-size: 70px;
    letter-spacing: 2px;
    color: white;
    z-index: -99994 !important;
    text-transform: uppercase;
    text-shadow: 0 0 20px black;
    font-family: "Archivo Black", sans-serif;

}

.about_textdiv {
    padding: 50px;
}

.about_textdiv h4{
    font-family: "Archivo Black", sans-serif;

}
.about_textdiv img {
    width: 95%;
    margin: 20px 40px;
}

.Expertise_div {
    background-color: #0a0a0a;
    padding: 60px 5px;
    text-align: center;
}

.Expertise_div h1 {
    color: white;
    font-family: "Archivo Black", sans-serif;


}


.Expertise_slidediv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.Expertise_slidediv a {
    text-decoration: none;
}

.Expertise_div_slide {
    margin: 10px;
    width: 350px;
    height: auto;
    text-align: center;
    border-radius: 4px;
    background-color: white;
    padding-bottom: 0px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.5s;

}

.Expertise_div_slide:hover {
    transform: translateY(-12px);
}

.Expertise_div_slide img {
    height: 80px;
    /* border: 5px solid white; */
    width: 80px;
    margin: 40px auto 20px auto;
    object-fit: cover;
    /* border-radius: 44px 44px 0 0; */
}

.Expertise_div_slide h4 {
    margin: 10px 20px 0 20px;
    font-weight: bold;
    font-size: 22px;
    font-family: "Archivo Black", sans-serif;

}

.Expertise_div_slide p {
    margin: 20px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;

}


@media screen and (max-width:767px) {
    .Aboutus_topdiv {
        margin-bottom: 23%;
    }

    .Aboutus_topdiv img {
        height: 250px;
    }

    .Aboutus_topdiv .hosp_aboutushead {
        margin-top: -42%;
        padding: 0 20px;
        text-align: center;

    }

    .Aboutus_topdiv .hosp_aboutushead h1 {
        font-size: 30px;
    }

}