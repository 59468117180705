.newfooter {
    background-color: #201d1d;
    padding: 40px;
    margin-top: 0px;
    position: relative;
}

img.footerlogo {
    width: 95px;
    border-radius: 6px;
    margin-top: 40px;
    margin-left: 40px;
}


.footer-sub-head {
    display: block;
    color: #ffffff;
    margin: 18px 30px 18px 0;
    font-size: 18px;
}

.menu_div {
    list-style: none;
    padding-left: 0px;
    margin: 10px 0px;
}

.menu_div li {
    margin-bottom: 10px;
    color: #e8e8e8;
}

.menu_div li a {
    text-decoration: none;
    color: #e8e8e8;
    font-size: 16px;
    line-height: 24px;
}

.mag {
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0px 8px 16px #00000029;
    border-radius: 4px;
    margin-top: 30px;
}

.footerlnk-head {
    font-size: 23px;
    color: #ffffff;
    font-weight: 600;
    text-transform: capitalize;
    margin-top: 40px;
    font-family: "Archivo Black", sans-serif;

}

.soc_ul_footer,
.soc-ul-footer {
    list-style: none;
    display: flex;
    padding-left: 0px;
    margin-top: 11px;
}

.soc_ul_footer li,
.soc-ul-footer li {
    margin-right: 15px;
}


.copyright {
    background-color: #2d2a2a;
    padding: 28px;
}

.copy p {
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    margin-bottom: 0px;
    font-family: Arial;
}

.copy p a{
    color: #ffffff;
    text-decoration: none;
}
.copy p a:hover{
    color: #639fff;
    text-decoration: none;
}
.whatsappimg{
    width: 200px;
    float: right;
    margin: 20px 0;
    cursor: pointer;

}
@media screen and (max-width:767px) {

}