#main-div {
    width: 290px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999999999999 !important;

}
.pro-sidebar{
    width: 290px;
    min-width: 290px;
}
.Menus-p{
    font-size: 12px;
    font-weight: bold;
    margin: 10px 0 3px 30px;
    color: white;
    letter-spacing: 1px;
}

.pro-sidebar .pro-menu .pro-menu-item{
    font-size: 16px !important;
    text-transform: uppercase;
    font-weight: 600;
    color: white;

}
.pro-sidebar .pro-menu a {
    padding: 0;
}

.pro-sidebar > .pro-sidebar-inner {
    /* background-color: white !important;
    background: white !important;
    color: rgb(60, 55, 55) !important; */
    margin-left: 0;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper{
    background-color: unset !important;
    font-size: 24px;
    /* color: #ffffff; */
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item{
    /* background-color: #f3f0f8 !important; */
    font-weight: 300 !important;
    background-color: #2c2c2f;

    /* background-image:url('../../assets/bg-texture.png'); */
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item .pro-item-content{
    font-weight: 400 !important;
    font-size: 14px !important;
}



.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus{
    color: rgb(60, 55, 55) !important;

}

.imgbox {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imglogo {
    width: 170px;
}

.pro-sidebar .pro-menu::-webkit-scrollbar {
    display: none;
}

.sidebar-content-div {
    color: rgb(60, 55, 55) !important;
    padding-left: 5px;
    overflow-y: scroll;
    height: 90vh;
    width: auto;
    background-color: #18181a;
    /* background-image:url('../../assets/bg-texture.png'); */

}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    padding-left: 0px;
}
.sidebar-content-div .pro-inner-item {
    padding: 8px 35px 8px 20px !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: rgb(60, 55, 55) !important;
    background-color: #f1eef7 !important;
    border-radius: 4px !important;
}

/* .sidebar-tb-item {
    color: black !important;
    font-size: 22px !important;
    font-weight: bold !important;
    margin-left: 10px;
} */

@media screen and (max-width:767px) {
    .sidebar-content-div {
        height: 100vh;
        padding: 40px 0;
    }
  
}

@media screen and (min-width:768px) and (max-width:992px) {}

/*  */