#BoxId {
    margin: 0 !important;
}

#BoxId .custom-img img {
    height: 100vh !important;
    width: 100%;
    /* filter: brightness(0.6); */
    object-fit: cover;

}

#BoxId .swiper .swiper-slide {
    padding-top: 0 !important;

}

#BoxId .swiper-pagination .swiper-pagination-bullet {
    width: 16px !important;
    height: 6px !important;
    border-radius: 2px !important;
}

/* #BoxId .swiper-button-prev,
#BoxId .swiper-button-next {
    background-color: rgb(0, 0, 0) !important;
    padding: 10px;
    color: white !important;
    width: 28px !important;
    height: 28px !important;
    z-index: 66 !important;
    box-shadow: 2px 1px 6px rgb(125, 85, 85) !important;
    font-weight: bold;
} */

/* #BoxId .swiper-button-prev {
    border-radius: 50% !important;
    margin: 0 25px;
}

#BoxId .swiper-button-next {
    border-radius: 50% !important;
    margin: 0 26px;
}

#BoxId {
    --swiper-navigation-size: 16px !important;
} */


.Boost_about_div {
    text-align: center;
    padding: 20px 0;
    background-color: #020202;
    color: white;
}

.Boost_about_div h1 {
    font-family: "Archivo Black", sans-serif;
    color: white;
    font-weight: bold;
    padding-top: 20px;
}

.Boost_about_div h2 {
    color: white;
    font-weight: bold;
    margin-bottom: 60px;
    font-family: "Archivo Black", sans-serif;
}


.Boost_about_div_home {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;

}


.Boost_about_div_home .imggdiv {
    padding: 10px 40px;

}

.Boost_about_div_home .imggdiv img {
    border-radius: 6px;
    width: 100%;
    height: 600px;
    object-fit: contain;
    border: 20px solid #fff;
    background-color: white;

}

.Boost_about_div_home .textdiv {
    padding: 10px 40px;
    text-align: start;

}

.Boost_about_div_home .textdiv h6 {
    font-size: 35px;
    font-weight: bold;
    /* border-bottom: 1px solid lightgray; */
    color: #2764d6;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-family: "Archivo Black", sans-serif;
}

.Boost_about_div_home .textdiv h5 {
    font-size: 30px;
    font-weight: bold;
    /* border-bottom: 1px solid lightgray; */
    color: #2764d6;
    margin-top: 20px;
}

.Boost_about_div_home .textdiv p {
    font-size: 17px;
    margin: 0px;
    letter-spacing: 1px;
    color: white;
    padding-top: 20px;
    text-align: justify;

}


.Boost_about_div_home .textdiv .morebtn {
    color: white;
    border: 1px solid white;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 700;
    padding: 10px 30px;
    margin-top: 40px;
}

.Boost_about_div_home .textdiv .morebtn:hover {
    background: linear-gradient(to right, #2764d6, #18418b);
    color: white;
    border: unset;

}



/* flip card */


.flip-card {
    background-color: white;
    width: 350px;
    height: 350px;
    border-radius: 20px;
    padding: 12px;
    overflow: hidden;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 20px;

}


.flip-card-front {
    color: black;
    border-radius: 20px 20px 0 0;
    border: 5px solid white;

}

.flip-card-front img {
    width: 100%;
    height: 100%;
    border-radius: 20px 20px 0 0;
    object-fit: cover;

}

.flip-card-back {
    background-color: white;
    border: 5px solid white;
    border-radius: 0 0 20px 20px;
    color: #1c1b1b;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px;
    transition: 0.9s;
}


.flip-card-back h1 {
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.flip-card-back p {
    margin: 0 0 20px 0;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    text-transform: capitalize;
    display: none;
}

.flip-card-back button {
    background: linear-gradient(to right, #553eb0, #7b3eb0);
    color: white;
    border: 2px solid #553eb0;
    padding: 10px 40px;
    font-weight: 600;
    text-transform: capitalize;
    display: none;
}

.flip-card-back button:hover {
    background: linear-gradient(to right, #553eb0, #7b3eb0);
    color: white;
    border: 2px solid #553eb0;
}

.flip-card:hover .flip-card-back {
    transform: translateY(-130px);
}


.flip-card:hover .flip-card-back p {
    display: block;
}


.flip-card:hover .flip-card-back button {
    display: block;

}

/* flip card */


.TradingCompany_div {
    background-color: #0a0a0a;
    padding: 0px 5px;
    text-align: center;
}

.TradingCompany_div h1 {
    font-size: 40px;
    color: white;
    padding: 30px 0;
    font-family: "Archivo Black", sans-serif;

}

.Trading_slidediv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Trading_slide {
    margin: 2.5px;
    /* width: 100%; */
    text-align: justify;
    border-radius: 6px;
    background-color: white;
    padding: 1px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.Trading_slide h4 {
    margin: 20px;
    font-size: 19px;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
}

.Trading_slide p {
    margin: 20px;
    font-size: 17px;
    letter-spacing: 1px;
    text-align: center;
}


.allpackages_div {
    background-color: black;
    padding: 40px 5px;
    text-align: center;
}

.allpackages_div h1 {
    font-weight: bold;
    font-size: 40px;
    color: white;
    padding: 40px 0 0 0;
    font-family: "Archivo Black", sans-serif;

}

.packages_slide {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.packages_life_Slider {
    margin: 20px;
    width: 350px;
    text-align: justify;
    border: 5px solid white;
    border-radius: 4px;
    background-color: white;
    padding-bottom: 10px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.packages_life_Slider img {
    height: 40vh;
    width: 100%;
    object-fit: cover;
    /* border-radius: 4px 4px 0 0; */
}

.packages_life_Slider h4 {
    margin: 20px 20px 0 20px;
    font-weight: 700;
    font-size: 24px;
    width: auto;
    color: black;
    text-align: center;
}


.PopularTours_div {
    background-color: black;
    padding: 40px 5px;
    text-align: center;
}

.PopularTours_div h1 {
    color: white;
    font-family: "Archivo Black", sans-serif;

}

.tour_slide {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.tour_slide a {
    text-decoration: none;
}

.populartour_Slider {
    margin: 20px;
    width: 340px;
    text-align: justify;
    border-radius: 44px 44px 16px 16px;
    background-color: white;
    padding-bottom: 0px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.populartour_Slider a {
    text-decoration: none;
    color: black;

}

.populartour_Slider img {
    height: 40vh;
    border: 5px solid white;
    width: 100%;
    object-fit: cover;
    border-radius: 44px 44px 0 0;
}

.populartour_Slider h4 {
    margin: 10px;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: #2764d6;
}

.populartour_Slider p {
    margin: 10px 20px;
    font-size: 15px;
    letter-spacing: 1px;
    text-align: center;
    display: -webkit-box;
    /* Create a flexible box layout */
    -webkit-line-clamp: 3;
    /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    /* Set the box orientation to vertical */
    overflow: hidden;
    /* Hide the overflowing content */
    text-overflow: ellipsis;
    /* Display the ellipsis (...) */

}

.PopularTours_div .booknowbtn {
    background: linear-gradient(to right, #2764d6, #18418b);
    color: white;
    text-transform: capitalize;
    font-size: 17px;
    padding: 12px 30px;
    margin-top: 10px;
    border-radius: 0 0 8px 8px;
}

.PopularTours_div .butnn {
    background: linear-gradient(to right, #2764d6, #18418b);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin-top: 40px;
}



.Civil_div {
    background-color: black;
    padding: 40px 5px;
    text-align: center;
}

.Civil_div h1 {
    color: white;
    font-family: "Archivo Black", sans-serif;

}

.Civil_slide {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.Civil_slide a {
    text-decoration: none;
}

.Civil_Slider {
    margin: 20px 10px;
    width: 270px;
    text-align: justify;
    border-radius: 44px 44px 16px 16px;
    background-color: white;
    padding-bottom: 0px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.Civil_Slider a {
    text-decoration: none;
    color: black;

}

.Civil_Slider img {
    height: 40vh;
    border: 5px solid white;
    width: 100%;
    object-fit: cover;
    border-radius: 44px 44px 0 0;
}

.Civil_Slider h4 {
    margin: 10px;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: #2764d6;
    display: -webkit-box;
    /* Create a flexible box layout */
    -webkit-line-clamp: 1;
    /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    /* Set the box orientation to vertical */
    overflow: hidden;
    /* Hide the overflowing content */
    text-overflow: ellipsis;
    /* Display the ellipsis (...) */
}

.Civil_Slider p {
    margin: 10px 20px;
    font-size: 15px;
    letter-spacing: 1px;
    text-align: center;
    display: -webkit-box;
    /* Create a flexible box layout */
    -webkit-line-clamp: 3;
    /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    /* Set the box orientation to vertical */
    overflow: hidden;
    /* Hide the overflowing content */
    text-overflow: ellipsis;
    /* Display the ellipsis (...) */

}

.Civil_div .booknowbtn {
    background: linear-gradient(to right, #2764d6, #1f458c);
    color: white;
    text-transform: capitalize;
    font-size: 17px;
    padding: 12px 30px;
    margin-top: 10px;
    border-radius: 0 0 8px 8px;
}

.Civil_div .butnn {
    background: linear-gradient(to right, #2764d6, #1f458c);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin-top: 40px;
}



.Sustainability_div {
    background-color: #0a0a0a;
    padding: 60px 5px;
    text-align: center;
}

.Sustainability_div h1 {
    color: white;
    font-family: "Archivo Black", sans-serif;

}


.Sustainability_slidediv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.Sustainability_slidediv a {
    text-decoration: none;
}

.Sustainability_div_slide {
    margin: 10px;
    width: 280px;
    height: 300px;
    text-align: center;
    border-radius: 14px 14px 10px 10px;
    background-color: white;
    padding-bottom: 0px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.Sustainability_div_slide img {
    height: 80px;
    /* border: 5px solid white; */
    width: 80px;
    margin: 10px auto 10px auto;
    object-fit: contain
        /* border-radius: 44px 44px 0 0; */
}

.Sustainability_div_slide h4 {
    margin: 10px 20px 0 20px;
    font-weight: bold;
    font-size: 22px;
}

.Sustainability_div_slide p {
    margin: 20px;
    font-size: 17px;
    letter-spacing: 1px;

}

.logistic_clients {
    text-align: center;
    width: 100%;
    margin: auto;
    padding: 50px;
    background-color: #eee9e9;
}

.logistic_clients h1 {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 40px;
    font-family: "Archivo Black", sans-serif;

}

.logistic_clients p {
    font-size: 16px;
}

.Projects_topimg {
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    width: 90% !important;
    padding: 20px;
    background-color: white;
}

.Projects_topimg img {
    height: 100px;
    width: 150px;
    object-fit: contain;
    margin: auto;
    align-items: center;
    border-radius: 4px;
}

.Projects_topimg p {
    font-weight: bold;
    margin-top: 20px;
}




.Satisfaction_div {
    background-color: #0a0a0a;
    padding: 60px 5px;
    text-align: center;
}

.Satisfaction_div h1 {
    color: white;
    font-family: "Archivo Black", sans-serif;

}


.Satisfaction_slidediv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.Satisfaction_slidediv a {
    text-decoration: none;
}

.Satisfaction_div_slide {
    margin: 10px;
    width: 280px;
    height: 200px;
    text-align: center;
    border-radius: 4px;
    background-color: white;
    padding-bottom: 0px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.Satisfaction_div_slide:hover {
    background-color: #b8cef7;
}

.Satisfaction_div_slide img {
    height: 80px;
    /* border: 5px solid white; */
    width: 80px;
    margin: 10px auto 10px auto;
    object-fit: contain
        /* border-radius: 44px 44px 0 0; */
}

.Satisfaction_div_slide h4 {
    margin: 10px 20px 0 20px;
    font-weight: bold;
    font-size: 22px;
}

.Satisfaction_div_slide p {
    margin: 20px;
    font-size: 17px;
    letter-spacing: 1px;

}



/* testimonial */
#testimonials {
    background-color: #eee9e9;
    padding-bottom: 10px;
}

.testimonials_h2 {
    font-size: 40px;
    font-weight: bold;
    padding: 30px 0 30px 50px;
    color: #56594d;
    font-family: "Archivo Black", sans-serif;

}

.testimonials_container {
    width: 100%;
    padding-bottom: 1rem;
}

/* .testimonials_container .swiper-wrapper{
    width: 100%;
  }
  .testimonials_container .swiper-wrapper .swiper-slide{
    width: 50% !important;
    margin: 0 5px;
  } */


.testimonial {
    background: #ffffff;
    text-align: center;
    padding: 2rem;
    border-radius: 0.21rem;
    user-select: none;
    height: 300px !important;
    margin: 30px 0;
}

.testimonial:hover {
    background-color: #b8cef7;
}

.client_avatar img {
    width: 5rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid rgba(73, 67, 90, 0.4);
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    background-color: unset;
    padding: 0px;
}

.client_name {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
}

.client_review {
    color: #000000;
    display: block;
    width: 80%;
    font-size: 16px;
    font-weight: 500;
    margin: auto;
}


/* end testimonial */

.section1 {
    background-color: #000000;
    min-height: 100vh;
    padding: 1rem 9% 6rem;
}

.section1 h1{
    font-family: "Archivo Black", sans-serif;

}

.contact-us-left {
    margin-top: 1px;
    font-size: 18px;
    color: white;
    text-align: center;
}


.contact-us .contact-us-form {
    padding: 50px 40px;
}

.contact-us .contact-us-form h2 {
    position: relative;
    font-size: 32px;
    color: white;
    font-weight: 600;
    line-height: 27px;
    text-transform: capitalize;
    margin-bottom: 12px;
    padding-bottom: 20px;
    text-align: left;

}


.contact-us .contact-us-form h2:before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 2px;
    width: 50px;
    background: white;
}

.contact-us .contact-us-form p {
    font-size: 14px;
    color: white;
    font-weight: 400;
    text-align: left;
    margin-bottom: 50px;
}

.contact-us .form {
    margin-top: 30px;
}

.contact-us .form .form-group {
    margin-bottom: 22px;
}

.contact-us .form .form-group input {
    width: 100%;
    height: 50px;
    border: 1px solid black;
    text-transform: capitalize;
    padding: 0px 18px;
    color: black;
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
}

.contact-us .form .form-group textarea {
    width: 100%;
    height: 100px;
    border: 1px solid black;
    text-transform: capitalize;
    padding: 18px;
    color: black;
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
}

.section1 .btnbtnbgprimary {
    background-color: #2764d6;
    color: white;
}

.section1 .btnbtnbgprimary:hover {
    background-color: #1e4388;
    color: white;
}

@media screen and (max-width:767px) {
    #BoxId .custom-img img {
        height: 250px !important;
    }

    #BoxId .swiper-button-prev {
        margin: 0 10px;
    }

    #BoxId .swiper-button-next {
        margin: 0 10px;
    }

    .Boost_about_div h2 {
        font-size: 19px;
        margin: 20px;
    }

    .Boost_about_div_home .imggdiv {
        padding: 10px 40px;

    }

    .Boost_about_div_home .imggdiv img {
        height: 300px;
        border: 2px solid #fff;

    }

    .Boost_about_div_home .textdiv {
        padding: 10px 20px;
    }

    .Boost_about_div_home .textdiv h6 {
        font-size: 30px;
    }

    .Boost_about_div_home .textdiv p {
        padding-top: 10px;

    }

    .TradingCompany_div h1 {
        font-size: 26px;
        padding: 30px 10px;
    }

    .Trading_slide {
        margin: 10px 2.5px;
        width: 90%;

    }

    .allpackages_div h1 {
        font-size: 30px;
    }

    .PopularTours_div h1 {
        font-size: 26px;
    }

    .populartour_Slider img {
        height: 45vh;
    }

    .PopularTours_div .butnn {
        font-size: 15px;
        padding: 8px 10px;
        width: 90%;
        margin: 40px auto;
    }

    .Sustainability_div_slide {
        width: 90%;
        justify-content: center;

    }

    .Civil_Slider {
        width: 90%;

    }

}