.vediotag{
    font-weight: bold;
    text-align: center;
    margin-top: 50px;
    text-transform: uppercase;
}
.bgdiv{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-top: 10px;
}
.vidio-divbox{
    width: 400px !important;
    height: 250px !important;
    margin:20px 10px;
    padding: 4px;
    border-radius: 4px;


}
.video-tagbtn{
    width: 390px !important;
    height: 240px !important;
    border-radius: 6px !important;
    
}
@media screen and (max-width:767px) {
    .vediotag{
        margin-top: 40px;
        text-transform: uppercase;
    }
    .bgdiv{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        padding-top: 0px;
    }
    .vidio-divbox{
        width: 95% !important;
        height: 250px !important;
        margin:5px 10px;
        padding: 0px 0 0 5px;
        border-radius: 0px !important;
    
    
    }
    .video-tagbtn{
        width: 95% !important;
        height: 250px !important;
        border-radius: 0px !important;
        
    }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
    .vediotag{
        margin-top: 40px;
        text-transform: uppercase;
    }
    .bgdiv{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        padding-top: 0px;
    }
    .vidio-divbox{
        width: 95% !important;
        height: auto !important;
        margin:15px 10px;
        padding: 0px 0 0 15px;
        border-radius: 0px !important;
    
    
    }
    .video-tagbtn{
        width: 95% !important;
        height: auto !important;
        border-radius: 0px !important;
        
    }
 }